import { Navigate, Outlet } from "react-router-dom";
import { IsTokenExpired } from "../common/jwtToken";
import Layout from "../layout";

const ProtectedRoute = ({ children, ...rest }) => {
  return localStorage.getItem("token") ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};
export default ProtectedRoute;
