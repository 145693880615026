import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class HttpService {
  get(url, params = {}) {
    return call("GET", url, params);
  }

  post(url, params = {}, payload = {}) {
    return call("POST", url, params, payload);
  }

  delete(url, params = {}, payload = {}) {
    return call("DELETE", url, params, payload);
  }

  put(url, params = {}, payload = {}) {
    return call("PUT", url, params, payload);
  }
}

function call(method, URL, params, payload = {}) {
  const opts = {
    method,
    url: API_URL + URL,
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  };
  if (params) opts.params = params;
  if (payload) opts.data = payload;

  return axios(opts);
}

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    throw new Error(err?.response?.data?.error);
    // switch (err && err.response.status) {
    //   case 400:
    //     throw new Error("Bad request");
    //   case 401:
    //     throw new Error("Unauthorized");
    //   case 403:
    //     throw new Error("Forbidden");
    //   case 404:
    //     throw new Error("Not Found");
    //   case 500:
    //     throw new Error("Internal Server error");
    //   case 502:
    //     throw new Error("Bad Gateway");
    //   default:
    //     throw new Error("Server Side Error");
    // }
  }
);

export default new HttpService();
